<template>
  <b-flix-submenu>
    <span slot="link"
      ><span class="menu-title"
        ><flixIcon :id="icon" class="flix-text-warning" /> {{ name }}</span
      ></span
    >
    <b slot="title" class="title"><flixIcon :id="icon" /> {{ name }}</b>
    <div slot="menu">
      <div class="items">
        <router-link
          v-for="(item, key) in items"
          :key="key"
          :to="item.link"
          class="flix-html-a"
          v-show="item.permission()"
        >
          <div class="icon">
            <flixIcon :id="item.icon" />
          </div>
          <div class="text">
            <b>{{ item.name }}</b>
          </div>
        </router-link>
      </div>
    </div>
  </b-flix-submenu>
</template>
<script>
export default {
  components: {},
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    name: {
      type: String,
      default() {
        return ''
      }
    },
    icon: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>
<style>
li[part='submenu'] div.submenu-items.open[data-v-4a5fceb2] {
  max-width: 600px;
}
</style>
<style lang="sass" scoped>
.menu-title
  display: flex
  gap: 7px
  justify-content: center
  align-items: center
  .flix-text-warning
    font-size: 1.5rem
.items
  display: grid
  overflow: hidden
  grid-template-columns: repeat(2, 1fr)
  grid-auto-rows: 1fr
  grid-column-gap: 10px
  grid-row-gap: 10px
  @media (max-width: 765px)
    grid-template-columns: repeat(1, 1fr)
    margin-top: 0px
    gap: 20px
  a
    flex: 1 1 calc(50% - 10px)
    padding: 10px
    box-sizing: border-box
    display: flex
    flex-direction: row
    align-items: center
    gap: 15px
    line-height: 1.2rem
    font-size: 1rem
    text-decoration: none
    &:hover
      background-color: #f0f2f4
    .icon
      font-size: 2rem
</style>
